import mutations from './mutations';
import {checkResponse} from '@/util/common-utils';
import router from '@/routes/protectedRoute';
import {DataService} from '@/dataService/dataService';
import {notification} from 'ant-design-vue';
import ConstantAPI from '@/config/ConstantAPI';
import {i18n} from '@/main';
import Cookies from 'js-cookie';

const state = () => ({
  tokenInfo: JSON.parse(Cookies.get('tokenInfo') || null),
  userInfo: { allMenu: JSON.parse(localStorage.getItem('allMenu') || null) },
  loading: false,
  uploading: false,
  error: '',
  routerLoaded: false,
  api: ConstantAPI.auth,
  view2fa: false,
  socketId: null,
  notifications: [],
  isContinue: true,
  page: -1,
  idUser: null,
});

function getPathOfFirstObject(tree) {
  if (tree && Array.isArray(tree) && tree.length > 0) {
    const firstItem = tree[0];
    if (firstItem.children === null) {
      return firstItem.path;
    } else {
      return getPathOfFirstObject(firstItem.children);
    }
  }
  return '/settings/profile-settings/profile';
}

const actions = {
  async getNotifications({ state, commit }) {
    state.page += 1;
    const params = {
      page: state.page,
      size: 10,
    };
    const notification = await DataService.callApi(
      ConstantAPI.user.GET_NOTIFICATIONS,
      null,
      params
    );
    if (notification.data.content === null) commit('setContinue', false);
    if (notification.data && notification.data.content) {
      commit('setNotifications', notification.data.content);
    }
  },
  async markReadNoti({ commit }, id) {
    try {
      const response = await DataService.patch(
        `${ConstantAPI.user.MARK_READ_NOTIFICATION.url}/${id}`
      );
      checkResponse(
        response,
        () => {
          commit('markReadNoti', id);
        },
        () => {}
      );
    } catch (error) {
      console.log(error);
    }
  },
  async login({ commit, state }, data) {
    try {
      commit('loginBegin', true);
      const payload = {
        ...data,
      };
      let response = await DataService.callApi(
        state.api.GET_TOKEN,
        new URLSearchParams(payload),
        null,
        { 'Content-Type': 'application/x-www-form-urlencoded',
          'Accept-Language': 'en'
        }
      );
      checkResponse(
        response,
        async () => {
          // return commit('set2fa', response.data)
          commit('setTokenInfo', response.data);
          response = await DataService.callApi(ConstantAPI.user.GET_USER_INFO);
          // lấy ra path url đầu tiên của user để truy cập vào đó
          const path = getPathOfFirstObject(response.data.menus);
          router.push(path);

          return commit('getUserInfoSuccess', response.data);
        },
        () => {
          commit('loginErr', response.message);
        }
      );
    } catch (err) {
      console.log(err);
      commit('loginErr', 'Username or password is incorrect');
    }
  },
  async verify({ commit, state }, data) {
    try {
      commit('loginBegin', true);
      let response = await DataService.callApi(
        state.api.GET_TOKEN,
        new URLSearchParams(data),
        null,
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      );
      checkResponse(
        response,
        async () => {
          response = await DataService.callApi(ConstantAPI.user.GET_USER_INFO);
          router.push('/');
          return commit('getUserInfoSuccess', response.data);
        },
        () => {
          commit('loginErr', response.message);
        }
      );
    } catch (err) {
      console.log(err);
      commit('loginErr', 'Username or password is incorrect');
    }
  },
  async logOut({ commit, state }) {
    try {
      await DataService.callApi(state.api.LOGOUT);
      if (state.tokenInfo && state.tokenInfo.accessToken) {
        Cookies.remove('tokenInfo');
        localStorage.removeItem('menus');
      }
      const url = window.location.pathname;
      if (!url.includes('auth')) {
        const urlParams = new URLSearchParams(window.location.search);
        const message = urlParams.get('message');
        if (message) {
          const { t } = i18n.global;
          state.error = t('account_not_register');
        }
        router.push('/auth/login');
      }
      commit('logoutSuccess');
    } catch (err) {
      commit('logoutErr', err);
    }
  },
  async getUserInfo({ state,commit }) {
    const response = await DataService.callApi(ConstantAPI.user.GET_USER_INFO);
    state.idUser = response.data.id
    commit('getUserInfoSuccess', response.data);
  },
  async changePassword({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      commit('setLoading', true);
      const data = {
        userId: state.userInfo.id,
        newPassword: payload.newPassword,
        oldPassword: payload.oldPassword,
      };
      const response = await DataService.callApi(
        state.api.CHANGE_PASSWORD,
        data
      );
      checkResponse(response, () => {
        notification.success({
          message: t('common.notification'),
          description: t('user.change_password_successfully'),
          duration: 4,
        });
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async forgot({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.FORGOT, payload);
      checkResponse(response, () => {
        notification.success({
          message: t('common.notification'),
          description: `Successfully sent password reset request to email address ${response.data.email}`,
          duration: 4,
        });
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async resetPassword({ commit, state }, payload) {
    try {
      const { t } = i18n.global;
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.RESET_PASSWORD,
        payload
      );
      checkResponse(response, () => {
        notification.success({
          message: t('common.notification'),
          description: t('user.change_password_successfully'),
          duration: 4,
        });
        router.push('/auth/login');
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async updateProfile({ commit }, payload) {
    try {
      // if (await showConfirm('Are you sure you want to save?')) {
      const { t } = i18n.global;
        commit('setLoading', true);
        payload.userRoles = null;
        const response = await DataService.callApi(
          ConstantAPI.user.PATCH_UPDATE,
          payload,
          null
        );
        checkResponse(response, async () => {
          notification.success({
            message: t('common.notification'),
            description: t('user.update_user_success'),
            duration: 4,
          });
          const response = await DataService.callApi(
            ConstantAPI.user.GET_USER_INFO
          );
          commit('getUserInfoSuccess', response.data);
        });
      // }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async changeAvatar({ commit, state }, file) {
    try {
      const { t } = i18n.global;
      commit('setUpLoading', true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('rootPath', 'avatar');
      let response = await DataService.callApi(
        ConstantAPI.upload_service.UPLOAD,
        formData,
        null,
        { 'Content-Type': 'multipart/form-data' }
      );
      state.userInfo.avatar = response.data.url
      checkResponse(response, async () => {
        response = await DataService.callApi(
          ConstantAPI.user.PATCH_UPDATE,
          { avatar: response.data.url },
          null
        );
        notification.success({
          message: t('common.notification'),
          description: t('user.update_avatar_success'),
          duration: 4,
        });
        // checkResponse(response, async () => {
        //   notification.success({
        //     message: t('common.notification'),
        //     description: t('user.update_avatar_success'),
        //     duration: 4,
        //   });
        //   const response = await DataService.get(
        //     ConstantAPI.user.GET_USER_INFO
        //   );
        //   commit('getUserInfoSuccess', response.data);
        // });
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setUpLoading', false);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters: {
    accessToken: (state) =>
      state.tokenInfo ? state.tokenInfo.accessToken : null,
    refreshToken: (state) =>
      state.tokenInfo ? state.tokenInfo.refreshToken : null,
    menus: (state) => (state.userInfo ? state.userInfo.menus : []),
    allMenu: (state) => (state.userInfo || {}).allMenu || [],
    loading: (state) => state.loading,
    uploading: (state) => state.uploading,
  },
};
