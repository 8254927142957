<template>
  <button
    :type="type"
    :class="`clip-button ${buttonClass} flex items-center justify-center`"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>
<script setup>
import VueTypes from 'vue-types';
import { computed, defineProps } from 'vue';
const props = defineProps({
  type: VueTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'link',
    'dark',
    'light',
    'white',
    'dashed',
    'error',
    'default',
  ]).def('default'),
  disabled: VueTypes.bool.def(false),
  size: VueTypes.oneOf(['large', 'default', 'small']).def('small'),
});
const buttonClass = computed(() => {
  const baseClass = '!px-5 outline-none border-none rounded-0';
  const typeClass =
    {
      primary: 'bg-ems-main1 text-white-a500 ',
      secondary: 'bg-[#444459] text-[#f5f5f5]',
      success: 'bg-[#20C997] text-white-a500',
      info: 'bg-[#2C99FF] text-white-a500',
      warning: 'bg-[#FA8B0C] text-white-a500',
      link: 'bg-[#1890ff] text-white-a500',
      danger: 'bg-[#FF4D4F] text-white-a500',
      dark: 'bg-[#272B41] text-white-a500',
      light: 'bg-[#9299B8] text-white-a500',
      white: 'bg-white-a500 text-[#5A5F7D]',
      dashed: 'bg-[#5A5F7D] text-white-a500',
      error: 'bg-[#f5222d] text-white-a500',
      default: 'bg-white-a500 text-[#5A5F7D]',
      grey: 'bg-ems-gray700 text-white-a500',
      reset: 'bg-ems-gray600 text-white-a500',
    }[props.type] || 'bg-white-a500 text-[#5A5F7D]';

  const sizeClass =
    {
      small: 'py-[8px]',
      default: 'py-[11px]',
      large: 'py-[14px]',
    }[props.size] || 'py-[8px]';
  const disabledClass = props.disabled
    ? 'opacity-75 cursor-not-allowed'
    : 'hover:bg-opacity-80';
  return `${baseClass} ${typeClass} ${sizeClass} ${disabledClass} `;
});
</script>
<style lang="scss" scoped>

.clip-button {
  //clip-path: polygon(
  //  20px 0%,
  //  calc(100% + 40px) 0%,
  //  calc(100% - 20px) 100%,
  //  -40px 100%
  //);
  border-radius: 4px !important;
  transition: all ease-in-out 0.4s;
}

</style>
