<template>
  <a-tree
      v-model:expandedKeys="expandedKeys"
      v-model:selectedKeys="selectedKeys"
      v-model:checkedKeys="checkedKeys"
      :checkable="checkable"
      :checkStrictly="checkStrictly"
      :tree-data="treeData"
      :fieldNames="fieldNames"
      @check="check"
      :show-icon="showIcon"
      :show-line="showLine"
      @select="select"
      class="tree-custom"
  >
    <template #icon="{ key, selected }">
      <slot name="custom-icon" :key="key" :selected="selected"></slot>
    </template>
    <template #title="tree">
      <a-dropdown :trigger="['contextmenu']">
        <span>{{ tree[fieldNames.title] }}</span>
        <template #overlay>
          <slot name="context-menu" :tree="tree"></slot>
        </template>
      </a-dropdown>
    </template>
  </a-tree>
</template>

<script>
import { defineComponent, ref } from 'vue';
import VueTypes from "vue-types";

export default defineComponent({
  name: 'Tree',
  emits: ['select'],
  props: {
    treeData: VueTypes.array.def([]),
    value: VueTypes.array.def([]),
    selected: VueTypes.number.def(null),
    checkStrictly: VueTypes.bool.def(true),
    checkable: VueTypes.bool.def(true),
    showLine: VueTypes.object.def(false),
    showIcon: VueTypes.bool.def(false),
    fieldNames: VueTypes.object.def({ children: 'children', title: 'title', key: 'key' })
  },
  setup(props) {
    const expandedKeys = ref([]);
    const selectedKeys = ref([props.selected]);
    return {
      expandedKeys,
      selectedKeys,
      checkedKeys: ref({checked: props.value}),
    };
  },
  methods: {
    check() {
      this.$emit('update:value', this.checkedKeys.checked);
    },
    select(key) {
      this.$emit('select', key[0]);
      this.$emit('update:selected', key[0]);
    }
  },
  watch: {
    value(val) {
      this.checkedKeys = {checked: val};
    },
    selected(val) {
      this.selectedKeys = [val];
    }
  }
});
</script>

<style lang="scss">

.tree-custom {
  .ant-tree-list-holder {
    background: #2B2A3A;
  }

  .ant-tree-switcher {
    position: relative;
    flex: none;
    align-self: stretch;
    width: 24px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background: #2B2A3A !important;
  }
}

</style>
